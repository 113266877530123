import * as React from "react";
import { PageProps } from "gatsby";
import Layout from "../../components/layout/layout";
import { Breadcrumb } from "../../components/breadcrumb/breadcrumb";
import { ProductBar } from "../../components/product-bar/product-bar";
import Container from "../../components/container/container"
import {Helmet} from "react-helmet";

const UrunlerPage: React.FC<PageProps> = ({ location }: PageProps) => {
  
  return (
    <Layout
      seoConfig={{
        title: "Ürünler",
        description:
          "Bisolnatur Öksürük Şurubu, Bisolduo Şurup ve Bisolduo Sprey hakkında detaylı bilgiler.",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Ürünler","item": "https://www.bisolnatur.com.tr/urunler/"}
                ]
            }
          `}
        </script>
      </Helmet>
      <Breadcrumb pathname={location.pathname} />
      <div style={{marginTop:"-60px"}}>
        <Container fullWidth className="bg-orange lighten2" >
          <Container >
            <h1>Ürünler</h1>
          </Container>
          </Container>
      </div>
      <ProductBar />
    </Layout>
  );
};

export default UrunlerPage;
